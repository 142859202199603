
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MDButton from "components/MDButton";
// Overview page components
import Header from "layouts/profile/components/Header";
import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import Container from '@mui/material/Container';
import { TextField } from '@mui/material';
import useAuthFetch from "util/apiFetcher";
import { useSandbox } from "ZustandState/useSanbox";
function Overview() {
  const sandbox = useSandbox()
  const authFetch = useAuthFetch()
  const [user, setUser] = useState({
    email: '',
    apiKey: '',
    name: '',
    business_name: '',
    encryptionKey: '',
    payinCallbackUrl: '',
    payoutCallbackUrl: ''
  })
  const [loading, setLoading] = useState(true)
  const payinValidationSchema = Yup.object({
    payinCallbackUrl: Yup.string().required('Payin Webhook Url is required'),
  });
  const payoutValidationSchema = Yup.object({
    payoutCallbackUrl: Yup.string().required('Payout Webhook Url is required'),
  });
  const initialPayinValues = {
    payinCallbackUrl: user?.payinCallbackUrl || ''
  };
  const initialPayoutValues = {
    payoutCallbackUrl: user?.payoutCallbackUrl || ''
  };
  async function getProfile() {
    try {
      const res = await authFetch(`/profile`, {
        method: 'GET',
      }, false)
      if (!res) return;
      setUser(res.responseData)
    } catch (e) {
      console.log(e)
    }
  }

  const handlePayinWebhookSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await authFetch(`/addPayinCallbackUrl`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
      if (!res) return;
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };
  const handlePayoutWebhookSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await authFetch(`/addPayoutCallbackUrl`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
      if (!res) return;
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    getProfile().then(() => {
      setLoading(false)
    })
  }, [sandbox])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={1} />
      <Header>
        <MDBox >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Basic information"
                info={{
                  Business: user?.business_name || '',
                  Name: user?.name || '',
                  email: user?.email || "",
                  // Apikey: user ? user.apiKey : "",
                  // encryptionKey: encryptionKey || '',

                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>
          </Grid>

        </MDBox>
      </Header>
      <MDBox mb={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
            <ProfileInfoCard
              title="Developer"
              info={{
                Apikey: user?.apiKey || "",
                encryptionKey: user?.encryptionKey || '',

              }}
              showBtnTest={true}
              action={{ route: "https://documenter.getpostman.com/view/18471050/2s9YysBg6b", tooltip: "Test Api Docs" }}
              shadow={false}
            />
          </Grid>
          <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
            <ProfileInfoCard
              title="API URL"
              info={{
                // Business: user ? user.business_name : '',
                // Name: user ? user.first_name || '' + " " + user.last_name || '' : '',
                // email: user ? user.emailId : "",
                Live: "https://api.gsxsolutions.com",
                Sandbox: "https://sandbox.gsxsolutions.com",

              }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
          </Grid>
        </Grid>
      </MDBox>
      {/* callbackUrl */}
      {
        loading ? null : (
          <MDBox mb={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
                <Card sx={{ height: "100%", width: "100vh", boxShadow: false && "none" }}>
                  <MDBox pt={6} pb={3}>
                    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                      <MDTypography variant="h6" gutterBottom align="left" mb={4}>
                        Payin Webhook Url
                      </MDTypography>
                      <Formik
                        initialValues={initialPayinValues}
                        validationSchema={payinValidationSchema}
                        enableReinitialize={true}
                        onSubmit={handlePayinWebhookSubmit}
                      >
                        {({ isSubmitting }) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Field
                                  name="payinCallbackUrl"
                                  as={TextField}
                                  variant="outlined"
                                  fullWidth
                                  label="Payin Webhook Url"
                                  helperText={<ErrorMessage name="payinCallbackUrl" />}
                                  error={Boolean(ErrorMessage.name)}
                                />
                              </Grid>
                            </Grid>
                            <MDBox mt={4} mb={1} display="flex" justifyContent="center">
                              <MDButton
                                variant="contained"
                                color="dark"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth='true'

                              >
                                {isSubmitting ? 'Updating...' : 'Update'}
                              </MDButton>
                            </MDBox>
                          </Form>
                        )}
                      </Formik>
                    </Container>

                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
                <Card sx={{ height: "100%", width: "100vh", boxShadow: false && "none" }}>
                  <MDBox pt={6} pb={3}>
                    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                      <MDTypography variant="h6" gutterBottom align="left" mb={4}>
                        Payout Webhook Url
                      </MDTypography>
                      <Formik
                        initialValues={initialPayoutValues}
                        enableReinitialize={true}
                        validationSchema={payoutValidationSchema}
                        onSubmit={handlePayoutWebhookSubmit}
                      >
                        {({ isSubmitting }) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Field
                                  name="payoutCallbackUrl"
                                  as={TextField}
                                  variant="outlined"
                                  fullWidth
                                  label="Payout Webhook Url"
                                  helperText={<ErrorMessage name="payoutCallbackUrl" />}
                                  error={Boolean(ErrorMessage.name)}
                                />
                              </Grid>
                            </Grid>
                            <MDBox mt={4} mb={1} display="flex" justifyContent="center">
                              <MDButton
                                variant="contained"
                                color="dark"
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth='true'

                              >
                                {isSubmitting ? 'Updating...' : 'Update'}
                              </MDButton>
                            </MDBox>
                          </Form>
                        )}
                      </Formik>
                    </Container>

                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )
      }

      {/* <MDBox mb={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
            <MDBox width="100%">
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="md"
                  coloredShadow="info"
                  mx={2}
                  mt={3}
                  p={1}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Callback URL
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  {!loading && <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <MDBox mb={2}>
                          <Field type="text" label="Callback URL" as={MDInput} fullWidth name="callbackUrl" />
                          <MDTypography color="error" variant="caption" >
                            <ErrorMessage name="callbackUrl" component="div" />
                          </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <Grid container justifyContent="center">
                            <Grid item>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                disabled={isSubmitting}

                              >
                                {isSubmitting ? 'Updating...' : 'Update'}
                              </MDButton>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>}
                </MDBox>
              </Card>
            </MDBox>

          </Grid>
          <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
            <MDBox width="100%">
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="md"
                  coloredShadow="info"
                  mx={2}
                  mt={3}
                  p={1}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Callback URL
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  {!loading && <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <MDBox mb={2}>
                          <Field type="text" label="Callback URL" as={MDInput} fullWidth name="callbackUrl" />
                          <MDTypography color="error" variant="caption" >
                            <ErrorMessage name="callbackUrl" component="div" />
                          </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <Grid container justifyContent="center">
                            <Grid item>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                disabled={isSubmitting}

                              >
                                {isSubmitting ? 'Updating...' : 'Update'}
                              </MDButton>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>}
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox> */}




      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
