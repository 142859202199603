
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import { useSandbox } from "ZustandState/useSanbox";
export default function data(transactions, setOpenDialog, callbackValueSetter) {
    const sandbox = useSandbox((state) => state.sandbox)
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: !sandbox ? [
            { Header: "time", accessor: "time", align: "left", width: "10%" },
            { Header: "date", accessor: "date", align: "left", width: "10%" },
            { Header: "gross amount", accessor: "amount", align: "right", width: "10%" },
            { Header: "tds", accessor: "tax", align: "right" },
            { Header: "net amount", accessor: "amountRecieved", align: "right" },
            { Header: "usdtrate", accessor: "usdtRate", width: "8%", align: "right" },
            { Header: "payout address", accessor: "payoutAddress", width: "15%", align: "left" },
            { Header: "status", accessor: "status", align: "center", width: "10%" },
            { Header: "customer id", accessor: "customerId", align: 'left', width: "10%", },
            { Header: "UTR", accessor: "utr", width: "10%", },
            { Header: "TXID", accessor: "txid", width: "10%", },
        ] : [
            { Header: "time", accessor: "time", align: "left", width: "10%" },
            { Header: "date", accessor: "date", align: "left", width: "10%" },
            { Header: "gross amount", accessor: "amount", align: "right", width: "10%" },
            { Header: "tds", accessor: "tax", align: "right" },
            { Header: "net amount", accessor: "amountRecieved", align: "right" },
            { Header: "usdtrate", accessor: "usdtRate", width: "8%", align: "right" },
            { Header: "payout address", accessor: "payoutAddress", width: "15%", align: "left" },
            { Header: "status", accessor: "status", align: "center", width: "10%" },
            { Header: "customer id", accessor: "customerId", align: 'left', width: "10%", },
            { Header: "UTR", accessor: "utr", width: "10%", },
            { Header: "TXID", accessor: "txid", width: "10%", },
            { Header: "Callback", accessor: "callback", align: "center", width: "10%" }
        ],


        rows: transactions && transactions.map((item, index) => ({
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item?.transaction_date).formattedTime || ''}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item.transaction_date).formattedDate || ''}
                </MDTypography>
            ),
            amount: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.amount || ''}
                </MDTypography>
            ),
            tax: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.taxDeducted || ''}
                </MDTypography>
            ),
            amountRecieved: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.amountReceived || ''}
                </MDTypography>
            ),
            usdtRate: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.usdt_rate}
                </MDTypography>
            ),
            payoutAddress: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.payout_address || ''}
                </MDTypography>
            ),
            status: item && (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={item?.status === "IN-PROCESS" ? "PENDING" : item?.status === "fail" ? "FAILED" : item?.status} color={item?.status === "Success" || item?.status === "success" || item?.status === "SUCCESS" ? "success" : item?.status === "IN-PROCESS" || item.status === "pending" ? "warning" : item?.status === "PENDING" ? "warning" : item.status === "expired" ? "info" : "error"} variant="gradient" size="sm" />
                </MDBox>
            ),
            customerId: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.customer_id || ''}
                </MDTypography>
            ),
            utr: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.utr || ''}
                </MDTypography>
            ),
            txid: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.transactionId || ''}
                </MDTypography>
            ),
            callback: sandbox ? (
                <MDButton onClick={() => {
                    let values = {
                        id: item.id,
                        transaction_id: item?.transactionId,
                        amount: item?.amount
                    }
                    callbackValueSetter(values)
                    setOpenDialog(true)
                }} size="small" color="info">
                    Send Callback
                </MDButton>
            ) : null,
        }))
    }
}
