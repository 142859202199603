
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
// import Transactions from "layouts/dashboard/components/Transactions"
// // Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import { useSandbox } from "ZustandState/useSanbox";
import Loader from "util/Loader";
import useAuthFetch from "util/apiFetcher";
function Dashboard() {
  const authFetch = useAuthFetch()
  const sandbox = useSandbox((state) => state.sandbox)
  const [allDataLoading, setAllDataLoading] = useState(true)
  // const [payinCardsData, setPayinCardsData] = useState({
  //   todayVolume: 0,
  //   successfulTransaction: 0,
  //   successRate: 0,
  //   yesterdayVolume: 0,
  //   wallet: 0
  // })
  // const [payoutCardsData, setPayoutCardsData] = useState({
  //   todayVolume: 0,
  //   successfulTransaction: 0,
  //   successRate: 0,
  //   yesterdayVolume: 0,
  //   wallet: 0
  // })
  const [statsCardsData, setStatsCardsData] = useState({
    usdtRate: 0,
    todaysPayin: 0,
    todaysPayout: 0,
    totalUsdtTx: 0
  })

  useEffect(() => {
    setAllDataLoading(true)
    fetchData()
  }, [sandbox])

  // async function getPayinVolumes() {
  //   try {
  //     const res = await authFetch(`/getPayinStats`, {
  //       method: 'GET',
  //     }, false)
  //     if (!res) return;
  //     const data = res?.responseData
  //     return data
  //   } catch (err) {
  //     console.log("Error Fetching getPayinVolumes: ", err)
  //   }
  // }
  // async function getPayoutVolumes() {
  //   try {
  //     const res = await authFetch(`/getPayoutStats`, {
  //       method: 'GET',
  //     }, false)
  //     if (!res) return;
  //     const data = res?.responseData
  //     return data
  //   } catch (err) {
  //     console.log("Error Fetching getPayoutVolumes: ", err)
  //   }
  // }
  async function getStats() {
    try {
      const res = await authFetch(`/stats`, {
        method: 'GET',
      }, false)
      if (!res) return;
      const data = res?.responseData
      return data
    } catch (err) {
      console.log("Error Fetching getStats: ", err)
    }
  }
  const fetchData = async () => {
    try {
      // const volumesData = await getPayinVolumes()
      // const payoutVolumesData = await getPayoutVolumes()
      const statsData = await getStats()
      // Once all data is fetched, update state and mark loading as false
      if (statsData) {
        // setPayinCardsData({
        //   todayVolume: volumesData.last24hr,
        //   successfulTransaction: volumesData.last24hrSuccess,
        //   successRate: 0,
        //   yesterdayVolume: volumesData.yesterday,
        //   wallet: volumesData.balance
        // });
        // setPayoutCardsData({
        //   todayVolume: payoutVolumesData.last24hr,
        //   successfulTransaction: payoutVolumesData.last24hrSuccess,
        //   successRate: 0,
        //   yesterdayVolume: payoutVolumesData.yesterday,
        //   wallet: payoutVolumesData.balance
        // });
        setStatsCardsData({
          usdtRate: statsData.usdtRate,
          todaysPayin: statsData.payin24,
          todaysPayout: statsData.payout24,
          totalUsdtTx: statsData.totalUsdtTx
        })
        //setAllDataLoading(false)
      }

      setAllDataLoading(false)
    } catch (err) {
      console.log("Error Fetching Data: ", err);
      setIsLoading(false); // Handle errors and stop loading
    }
  };

  // const recieveStats = (data) => {
  //   if (data === "all") {
  //     let adminBody = {
  //       emailId: adminEmail || '',
  //       apiKey: adminApiKey || ''
  //     }

  //     fetchData(adminBody)
  //   } else {
  //     setCardsData({
  //       todayVolume: data?.last24hr,
  //       successfulTransaction: data?.successfulTransactions,
  //       successRate: data?.successRate,
  //       yesterdayVolume: data?.yesterday,
  //       wallet: data?.balance,
  //     })
  //   }

  // }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        allDataLoading ? (<Loader />) : (
          <MDBox py={3}>
            {/* payin cards */}
            {/* <MDTypography mb={3} mx={1} variant="h6" gutterBottom>
              PAYIN
            </MDTypography> */}
            {/* <Grid container spacing={3}>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="7FDF9A"
                    title="Today's Volume"
                    count={Number(payinCardsData?.todayVolume).toFixed(0)}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than lask week",
                  // }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    // icon="leaderboard"
                    title="Successful Transactions"
                    color="D4BEF0"
                    count={payinCardsData.successfulTransaction || 0}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+0%",
                  //   label: "than last month",
                  // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="BBF2EA"
                    // icon="store"
                    title="Success Rate"
                    count={Math.round(payinCardsData?.successRate) + '%'}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+0%",
                  //   label: "than yesterday",
                  // }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="FFFED2"
                    //icon="equalizer"
                    title="Yesterday's Volume"
                    count={`${Number(payinCardsData?.yesterdayVolume).toFixed(0)}`}
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   // label: "Just updated",
                  // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="C9DEDE"
                    //icon="wallet"
                    title="Wallet"
                    count={payinCardsData?.wallet.toFixed(0)}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+0%",
                  //   label: "than lask week",
                  // }}
                  />
                </MDBox>
              </Grid>
            </Grid> */}

            {/* payout cards */}
            {/* <MDTypography mt={5} mb={3} mx={1} variant="h6" gutterBottom>
              PAYOUT
            </MDTypography> */}
            {/* <Grid container spacing={3}>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="7FDF9A"
                    title="Today's Volume"
                    count={Number(payoutCardsData?.todayVolume).toFixed(0)}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than lask week",
                  // }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    // icon="leaderboard"
                    title="Successful Transactions"
                    color="D4BEF0"
                    count={payoutCardsData.successfulTransaction || 0}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+0%",
                  //   label: "than last month",
                  // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="BBF2EA"
                    // icon="store"
                    title="Success Rate"
                    count={Math.round(payoutCardsData?.successRate) + '%'}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+0%",
                  //   label: "than yesterday",
                  // }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="FFFED2"
                    //icon="equalizer"
                    title="Yesterday's Volume"
                    count={`${Number(payoutCardsData?.yesterdayVolume).toFixed(0)}`}
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   // label: "Just updated",
                  // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="C9DEDE"
                    //icon="wallet"
                    title="Wallet"
                    count={payoutCardsData?.wallet.toFixed(0)}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+0%",
                  //   label: "than lask week",
                  // }}
                  />
                </MDBox>
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  color="success"
                  icon="call_received"
                  title="Today's Payin Volume"
                  // description="Freelance Payment"
                  value={`₹${statsCardsData.todaysPayin}`}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  color="warning"
                  icon="call_made"
                  title="Today's Payout Volume"
                  // description="Freelance Payment"
                  value={`₹${statsCardsData.todaysPayout}`}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon="paid"
                  title="USDT Rate"
                  // description="current usdt rate"
                  value={`$${statsCardsData.usdtRate}`}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  color="primary"
                  icon="payments"
                  title="Today's USDT Tx"
                  // description="Freelance Payment"
                  value={`$${statsCardsData.totalUsdtTx}`}
                />
              </Grid>

            </Grid>

            {/* <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Transactions recieveStatsFunc={recieveStats} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
              </Grid>
            </MDBox> */}
          </MDBox>
        )
      }

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
