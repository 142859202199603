import { useConfig } from 'config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const useAuthFetch = () => {
    const { apiUrl } = useConfig()
    const navigate = useNavigate()
    const authFetch = async (url, options = {}, sendSuccess = true) => {
        try {
            const userApiKey = localStorage.getItem("gpay_user_apiKey");
            const userToken = localStorage.getItem("gpay_user_token");

            const defaultHeaders = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
                'apikey': userApiKey,
            };
            let baseUrl;
            url.includes('/manualCallback') ? baseUrl = apiUrl + '/callback' : baseUrl = apiUrl + '/user/dashboard';
            const isLoginRoute = url.includes('/login');

            const headers = isLoginRoute ? options.headers : { ...defaultHeaders, ...options.headers };
            const config = {
                method: options.method || 'GET',
                headers: headers,
                body: options.body ? options.body : undefined,
                ...options,
            };

            const response = await fetch(baseUrl + url, config);
            const res = await response.json();

            if (res.responseCode === 403) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                localStorage.removeItem('gpay_user_token');
                localStorage.removeItem('gpay_user_apiKey');
                navigate('/authentication/sign-in')
                return;
            }

            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                return;
            }

            if (sendSuccess) {
                toast.success(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
            }

            return res;
        } catch (error) {
            console.error('Fetch error:', error);
            if (sendSuccess) {

                toast.error('An error occurred. Please try again later.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
            }
            throw error;
        }
    };

    return authFetch;
};

export default useAuthFetch;
