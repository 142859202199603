
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Transactions from "layouts/payout-transaction/components/Transactions"
import { useEffect, useState } from "react";
import useAuthFetch from "util/apiFetcher";
import { useSandbox } from "ZustandState/useSanbox";
import Loader from "util/Loader";
function Transaction() {
    const authFetch = useAuthFetch()
    const sandbox = useSandbox((state) => state.sandbox)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const [cardsData, setCardsData] = useState({
        todayVolume: 0,
        successfulTransaction: 0,
        successRate: 0,
        yesterdayVolume: 0,
        wallet: 0
    })
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setAllDataLoading(true)
        fetchData()
    }, [sandbox])
    async function getVolumes() {
        try {
            const res = await authFetch(`/getPayoutStats`, {
                method: 'GET',
            }, false)
            if (!res) return;
            const data = res?.responseData
            return data
        } catch (err) {
            console.log("Error Fetching getVolumes: ", err)
        }
    }
    const fetchData = async () => {
        try {
            const volumesData = await getVolumes()
            // Once all data is fetched, update state and mark loading as false
            if (volumesData) {
                setCardsData({
                    todayVolume: volumesData.last24hr,
                    successfulTransaction: volumesData.last24hrSuccess,
                    successRate: 0,
                    yesterdayVolume: volumesData.yesterday,
                    wallet: volumesData.balance
                });
                // setAllDataLoading(false)
                setIsLoading(false);
            }

            setAllDataLoading(false)
        } catch (err) {
            console.log("Error Fetching Data: ", err);
            setIsLoading(false); // Handle errors and stop loading
        }
    };
    // const recieveStats = (data) => {
    //     if (data === "all") {
    //         let adminBody = {
    //             emailId: adminEmail || '',
    //             apiKey: adminApiKey || ''
    //         }

    //         fetchData(adminBody)
    //     } else {
    //         setCardsData({
    //             todayVolume: data?.last24hr,
    //             successfulTransaction: data?.successfulTransactions,
    //             successRate: data?.successRate,
    //             yesterdayVolume: data?.yesterday,
    //             wallet: data?.balance,
    //         })
    //     }

    // }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        {
                            !isLoading && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={2.4} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="7FDF9A"
                                                title="Today's Volume"
                                                count={cardsData.todayVolume}
                                            // percentage={{
                                            //   color: "success",
                                            //   amount: "+55%",
                                            //   label: "than lask week",
                                            // }}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2.4} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                // icon="leaderboard"
                                                title="Successful Tx"
                                                color="D4BEF0"
                                                count={cardsData?.successfulTransaction || 0}
                                            // percentage={{
                                            //   color: "success",
                                            //   amount: "+0%",
                                            //   label: "than last month",
                                            // }}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={2.4} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="BBF2EA"
                                                // icon="store"
                                                title="Success Rate"
                                                count={Math.round(cardsData?.successRate) + '%'}
                                            // percentage={{
                                            //   color: "success",
                                            //   amount: "+0%",
                                            //   label: "than yesterday",
                                            // }}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2.4} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="FFFED2"
                                                //icon="equalizer"
                                                title="Yesterday's Volume"
                                                count={`${Number(cardsData?.yesterdayVolume)?.toFixed(0)}`}
                                            // percentage={{
                                            //   color: "success",
                                            //   amount: "",
                                            //   // label: "Just updated",
                                            // }}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={2.4} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="C9DEDE"
                                                //icon="wallet"
                                                title="Wallet Balance"
                                                count={cardsData?.wallet?.toFixed(0)}
                                            // percentage={{
                                            //   color: "success",
                                            //   amount: "+0%",
                                            //   label: "than lask week",
                                            // }}
                                            />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <MDBox mt={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    {/* <Transactions recieveStatsFunc={recieveStats} /> */}
                                    <Transactions fetchVolume={fetchData} />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </MDBox>
                )
            }

            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Transaction;
