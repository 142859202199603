
import Dashboard from "layouts/dashboard";
// import LandingPage from "layouts/landingPage";
import PayinTransaction from "layouts/transactions";
import PayoutTransaction from "layouts/payout-transaction";
import SignIn from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password/cover";
import Logout from "layouts/authentication/logout";
import Profile from "layouts/profile"
import Icon from "@mui/material/Icon";
import Settlement from "layouts/settlements";
import Topup from "layouts/topups";
const routes = [
  // {
  //   // type: "collapse",
  //   // name: "LandingPage",
  //   key: "landing-page",
  //   // icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/",
  //   component: <LandingPage />,
  // },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Payin Transactions",
    key: "payin-transactions",
    icon: <Icon fontSize="small">call_received</Icon>,
    route: "/payin-transactions",
    component: <PayinTransaction />,
  },
  {
    type: "collapse",
    name: "Payout Transactions",
    key: "payout-transactions",
    icon: <Icon fontSize="small">call_made</Icon>,
    route: "/payout-transactions",
    component: <PayoutTransaction />,
  },
  {
    type: "collapse",
    name: "Settlements",
    key: "settlements",
    icon: <Icon fontSize="small">checklist</Icon>,
    route: "/settlements",
    component: <Settlement />
  },
  // {
  //   type: "collapse",
  //   name: "Topups",
  //   key: "topups",
  //   icon: <Icon fontSize="small">description</Icon>,
  //   route: "/topups",
  //   component: <Topup />,
  // },
  // {
  //   type: "collapse",
  //   name: "Payouts",
  //   key: "payouts",
  //   icon: <Icon fontSize="small">arrow_outward</Icon>,
  //   route: "/payouts",
  //   component: <PayoutDash />,
  // },
  // // {
  // //   type: "collapse",
  // //   name: "Billing",
  // //   key: "billing",
  // //   icon: <Icon fontSize="small">receipt_long</Icon>,
  // //   route: "/billing",
  // //   component: <Billing />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "RTL",
  // //   key: "rtl",
  // //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  // //   route: "/rtl",
  // //   component: <RTL />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "Notifications",
  // //   key: "notifications",
  // //   icon: <Icon fontSize="small">notifications</Icon>,
  // //   route: "/notifications",
  // //   component: <Notifications />,
  // // },
  {
    type: "collapse",
    name: "Developer Settings",
    key: "developer-settings",
    icon: <Icon fontSize="small">code</Icon>,
    route: "/developer",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Log Out",
    key: "Log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/logout",
    component: <Logout />,
  },
  {
    //type: "collapse",
    //name: "Reset Password",
    key: "change-password",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/change-password",
    component: <ResetPassword />,
  },
  {
    //type: "collapse",
    //name: "Sign In",
    key: "sign-in",
    //icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },

];

export default routes;
